import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { calculateAverage } from "./calculateAverage";

const PercentChange = ({
  currentValue,
  datasetIndex,
  previousAverageScores, // New prop
}) => {
  const calculatePercentChange = (current, previous) => {
    // const current = parseFloat(currentValue);
    // const previous = parseFloat(previousAverageScores);

    // console.log(current, previous);

    if (isNaN(current) || isNaN(previous)) {
      return "N/A";
    }

    if (previous === 0 || current === 0) {
      return "0%";
    }

    const change = ((current - previous) / Math.abs(previous)) * 100;
    if (current === previous) return "0%";
    if (!isFinite(change)) return "";
    return `${change > 0 ? "+" : ""}${change.toFixed(2)}%`;
  };

  const percentChange = calculatePercentChange(
    currentValue,
    previousAverageScores[datasetIndex]
      ? previousAverageScores[datasetIndex]
      : previousAverageScores
  );

  const changeClass =
    percentChange !== "N/A" && parseFloat(percentChange) > 0
      ? "positive-change"
      : percentChange !== "N/A" && parseFloat(percentChange) < 0
      ? "negative-change"
      : "";

  const changeIcon =
    percentChange !== "N/A" && parseFloat(percentChange) > 0 ? (
      <FontAwesomeIcon icon={faArrowUp} />
    ) : percentChange !== "N/A" && parseFloat(percentChange) < 0 ? (
      <FontAwesomeIcon icon={faArrowDown} />
    ) : (
      ""
    );

  return (
    <span className={`percent-change ${changeClass}`}>
      {changeIcon} {percentChange}
    </span>
  );
};

export default PercentChange;
