// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./AuthContext";
import PrivateRoute from "./PrivateRoute";
import Auth from "./Auth";
import Login from "./Login";
import SignUp from "./SignUp";
import Dashboard from "./Dashboard";
import HomePage from "./HomePage";
import RequireApproval from "./RequireApproval";
import { isSandboxMode } from "./firebase";

function App() {
  const { authIsReady } = useContext(AuthContext);
  const appClassName = `App ${isSandboxMode() ? "sandbox-mode" : "prod-mode"}`;
  if (!authIsReady) {
    // You can return a loading indicator here
    return <div>Loading...</div>;
  }
  return (
    <div
      className={appClassName}
      data-environment={isSandboxMode() ? "sandbox" : "prod"}
    >
      <Router>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/*"
            element={
              <RequireApproval>
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              </RequireApproval>
            }
          />

          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
