// AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { auth } from "./firebase";
import {
  onAuthStateChanged,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [authIsReady, setAuthIsReady] = useState(false);

  const createUserDocument = async (user) => {
    if (!user) return;

    const userRef = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      const { displayName, email, photoURL, providerData } = user;
      const createdAt = new Date();

      try {
        await setDoc(userRef, {
          displayName: displayName || "User",
          email,
          photoURL,
          createdAt,
          lastLogin: createdAt,
          isUser: true, // Default role for all new users
          isApproved: false, // Initially set to unapproved
          isAdmin: false, // Not an admin by default
          superAdmin: false, // Not a super admin by default
          provider: providerData[0].providerId,
        });
        console.log("User document created successfully");
      } catch (error) {
        console.error("Error creating user document:", error);
      }
    } else {
      // Update last login
      await setDoc(userRef, { lastLogin: new Date() }, { merge: true });
    }
  };

  const signup = async (email, password, displayName) => {
    try {
      // Set persistence
      await setPersistence(auth, browserLocalPersistence);

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await updateProfile(user, { displayName });
      await createUserDocument(user);
      // Fetch user roles and merge with currentUser
      const updatedUser = await fetchUserRoles(user.uid);
      setCurrentUser(updatedUser);
      return user;
    } catch (error) {
      console.error("Error signing up:", error);
      throw error;
    }
  };

  const signin = async (method, ...args) => {
    try {
      // Set persistence
      await setPersistence(auth, browserLocalPersistence);

      let userCredential;
      switch (method) {
        case "email":
          userCredential = await signInWithEmailAndPassword(auth, ...args);
          break;
        case "google":
          userCredential = await signInWithPopup(
            auth,
            new GoogleAuthProvider()
          );
          break;
        case "facebook":
          userCredential = await signInWithPopup(
            auth,
            new FacebookAuthProvider()
          );
          break;
        case "twitter":
          userCredential = await signInWithPopup(
            auth,
            new TwitterAuthProvider()
          );
          break;
        case "microsoft":
          userCredential = await signInWithPopup(
            auth,
            new OAuthProvider("microsoft.com")
          );
          break;
        default:
          throw new Error("Invalid sign-in method");
      }

      const user = userCredential.user;
      await createUserDocument(user);
      // Fetch user roles and merge with currentUser
      const updatedUser = await fetchUserRoles(user.uid);
      setCurrentUser(updatedUser);
      return user;
    } catch (error) {
      console.error("Error signing in:", error);
      throw error;
    }
  };

  const logout = () => signOut(auth);

  // Fetch user role information from Firestore
  const fetchUserRoles = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "users", userId));
      if (userDoc.exists()) {
        return { ...auth.currentUser, ...userDoc.data() };
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
    return auth.currentUser; // Fallback to current auth user if unable to fetch roles
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch user roles from Firestore and merge with the authenticated user object
        const updatedUser = await fetchUserRoles(user.uid);
        setCurrentUser(updatedUser);
      } else {
        setCurrentUser(null);
      }
      setAuthIsReady(true); // Set authIsReady to true when Firebase initializes auth state
    });

    return () => unsubscribe();
  }, []);

  const value = {
    currentUser,
    authIsReady,
    signup,
    signin,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
