// HeroSection.js
import React from "react";
import uselyImage from "./images/uselyapp-group.png";
import { Link } from "react-router-dom";
const HeroSection = () => (
  <section className="hero-section">
    <div className="hero-content">
      <h1>Bringing science into art.</h1>
      <p>
        Usely assists design, research, and product teams in evaluating
        enterprise software usability. Compare your software to competitors to
        see how it stacks up.
      </p>

      <button className="cta-button">
        <Link to="/signup" className="signup-btn">
          Start Tracking Usability
        </Link>
      </button>
    </div>
    <div className="hero-image">
      <img src={uselyImage} alt="Usely" />
    </div>
  </section>
);

export default HeroSection;
