import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ currentPage, currentView, updateView }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(window.location.href);
    const viewParam = url.searchParams.get("view");
    if (viewParam) {
      updateView(viewParam);
    }
  }, [updateView]);

  const handleViewChange = (view) => {
    const url = new URL(window.location.href);
    url.searchParams.set("view", view);
    window.history.pushState({}, "", url);
    updateView(view);
  };

  return (
    <div className="header-bar">
      <h1>{currentPage}</h1>
      <div className="toggleswitch">
        <button
          onClick={() => handleViewChange("approved")}
          className={`toggle-link ${
            currentView === "approved" ? "active" : ""
          }`}
        >
          Approved Users
        </button>
        <button
          onClick={() => handleViewChange("nonApproved")}
          className={`toggle-link ${
            currentView === "nonApproved" ? "active" : ""
          }`}
        >
          Non-Approved Users
        </button>
      </div>
      <div className="actions">
        <button className="secondary">
          <FontAwesomeIcon icon={faPlus} /> Add User
        </button>
      </div>
    </div>
  );
};

export default ToggleSwitch;
