import React, { useCallback, useState, useEffect } from "react"; // Added useState for tracking note index
import "./ThirdPane.css";
import ScoreWithPercentChange from "./ScoreWithPercentChange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faNoteSticky,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
const ThirdPane = ({
  selectedDataPoint,
  heuristicData,
  selectedPeriod,
  closeThirdPane,
  previousPeriod,
  isHomePage,
  pages,
}) => {
  // State for tracking current note index
  const [noteIndex, setNoteIndex] = useState(0);

  // Extract the current notes for the selected heuristic
  const notesArray = Array.isArray(
    selectedDataPoint.notes?.[selectedDataPoint.index]
  )
    ? selectedDataPoint.notes[selectedDataPoint.index]
    : [];
  // Update noteIndex when notesArray changes
  useEffect(() => {
    if (notesArray && notesArray.length > 0) {
      setNoteIndex(0);
    } else {
      setNoteIndex(-1); // Indicates no valid note
    }
  }, [notesArray]);

  const [expandedNotes, setExpandedNotes] = useState({});

  const getPageData = useCallback(
    (pageId) => {
      if (
        !heuristicData ||
        !Array.isArray(heuristicData) ||
        !heuristicData[selectedPeriod]
      ) {
        return null;
      }

      const currentPeriodData = heuristicData[selectedPeriod];
      const pageData = currentPeriodData[pageId];

      if (!pageData || !pageData.datasets) {
        return null;
      }

      const page = pages.find((p) => p.id === pageId);
      if (!page) return null;

      const previousPeriodData =
        previousPeriod !== null ? heuristicData[previousPeriod] : null;

      return {
        labels: pageData.labels || [],
        datasets: pageData.datasets.map((dataset) => ({
          ...dataset,
          label: page.title,
          id: pageId,
        })),
        previousData: previousPeriodData,
      };
    },
    [heuristicData, selectedPeriod, previousPeriod, pages]
  );

  if (!selectedDataPoint) return null;

  const currentPeriodData = heuristicData?.[selectedPeriod];
  const isTreeMapView = selectedDataPoint.label === selectedDataPoint.pageLabel;

  // Functions to handle next and previous note actions
  const handleNextNote = () => {
    setNoteIndex((prevIndex) => Math.min(prevIndex + 1, notesArray.length - 1));
  };

  const handlePrevNote = () => {
    setNoteIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Generate unique key per note per release
  const getNoteKey = (noteIndex) => {
    return `${selectedDataPoint.releaseId}-${noteIndex}`;
  };

  return (
    <div className="third-pane">
      <div className="average-score">
        <button className="close-third-pane" onClick={closeThirdPane}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <div className="label-container">
          <div
            className="color-square"
            style={{ backgroundColor: selectedDataPoint.color }}
          ></div>
          <label>
            <span className="release-number">
              {currentPeriodData?.release ?? "N/A"}
            </span>
            <span className="relaese-name">{selectedDataPoint.pageLabel}</span>
          </label>
        </div>
      </div>
      <div
        className="heuristic"
        style={{ backgroundColor: selectedDataPoint.color }}
      >
        <h3>
          <FontAwesomeIcon icon={faFlag} /> Heuristic Score
        </h3>
        <ScoreWithPercentChange
          key={`thirdpane-${selectedDataPoint.id}-${selectedDataPoint.index}`}
          score={selectedDataPoint.value.toFixed(2)}
          label={selectedDataPoint.pageLabel}
          data={getPageData(selectedDataPoint.id)}
          previousData={getPageData(selectedDataPoint.id).previousData}
          index={!isTreeMapView ? selectedDataPoint.index : 0}
          isHomePage={isHomePage}
          pageId={selectedDataPoint.id}
          scorePage={selectedDataPoint.scorePage}
          dataPointSelected={selectedDataPoint?.index > -1}
        />
        <label>{selectedDataPoint.label}</label>
        <p>{selectedDataPoint.description}</p>
      </div>

      {/* Notes Section with Slideshow */}
      {notesArray.length > 0 ? (
        <div className="notes-slider">
          <div className="notesHeader">
            <h3>
              <FontAwesomeIcon icon={faNoteSticky} /> Notes
            </h3>
            {notesArray.length > 1 && (
              <div className="notesControls">
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className={`nav-arrow ${noteIndex === 0 ? "disabled" : ""}`}
                  onClick={handlePrevNote}
                />
                <div className="note-indicator">
                  {noteIndex + 1} of {notesArray.length}
                </div>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={`nav-arrow ${
                    noteIndex === notesArray.length - 1 ? "disabled" : ""
                  }`}
                  onClick={handleNextNote}
                />
              </div>
            )}
          </div>
          <div className="note-content notes">
            {noteIndex >= 0 && notesArray[noteIndex] ? (
              <p className="note-text">
                {notesArray[noteIndex].length > 200 ? (
                  <>
                    {expandedNotes[getNoteKey(noteIndex)] ? (
                      <>
                        {notesArray[noteIndex]}{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setExpandedNotes((prev) => ({
                              ...prev,
                              [getNoteKey(noteIndex)]: false,
                            }));
                          }}
                        >
                          less
                        </a>
                      </>
                    ) : (
                      <>
                        {notesArray[noteIndex].slice(0, 200)}...{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setExpandedNotes((prev) => ({
                              ...prev,
                              [getNoteKey(noteIndex)]: true,
                            }));
                          }}
                        >
                          more
                        </a>
                      </>
                    )}
                  </>
                ) : (
                  notesArray[noteIndex]
                )}
              </p>
            ) : (
              <p>No notes available</p>
            )}
          </div>
        </div>
      ) : (
        <div className="notes">
          <p>No notes available</p>
        </div>
      )}

      <div className="links">
        <h3>Links</h3>
        {(typeof selectedDataPoint.links === "string"
          ? [selectedDataPoint.links]
          : selectedDataPoint.links?.[selectedDataPoint.index] || []
        ).length > 0 ? (
          <ul>
            {(Array.isArray(selectedDataPoint.links)
              ? selectedDataPoint.links
              : selectedDataPoint.links[selectedDataPoint.index]
            ).map((link, i) => (
              <li key={i}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <p>No links available</p>
        )}
      </div>
    </div>
  );
};

export default ThirdPane;
