// ContentSection.js
import React from "react";
import { Link } from "react-router-dom";
const ContentSection = () => (
  <section className="content-section">
    <div className="section-highlight">
      <h2>Transforming Usability into Your Competitive Edge</h2>
      <p>
        In the fast-paced world of enterprise software, exceptional usability
        isn't just an advantage—it's a necessity. At Usely, we empower Design
        and User Research teams to scientifically assess and elevate the
        usability of your software, ensuring it not only meets but exceeds user
        expectations.
      </p>
      <button>
        <Link to="/signup" className="signup-btn">
          Transform Your Software
        </Link>
      </button>
    </div>
  </section>
);

export default ContentSection;
