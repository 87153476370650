import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

const RequireApproval = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkApprovalStatus = async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists() && userDoc.data().isApproved) {
          setIsApproved(true);
        } else {
          const handleSignupMessage = async () => {
            alert(
              "Please wait for approval or contact your administrator (Ryan) to gain access."
            );
            navigate("/");
          };
          handleSignupMessage();
        }
      }
      setLoading(false);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkApprovalStatus(user);
      } else {
        navigate("/auth");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return isApproved ? children : null;
};

export default RequireApproval;
