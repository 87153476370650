// SignUp.js

import React, { useState } from "react";
import { auth } from "./firebase";
import { db } from "./firebase";
import { doc, setDoc } from "firebase/firestore";
import {
  signInWithPopup,
  signInWithRedirect,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  googleProvider,
  facebookProvider,
  twitterProvider,
  microsoftProvider,
} from "./authProviders";
import Navbar from "./Navbar";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [resetMessage, setResetMessage] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage("Password reset email sent. Check your inbox.");
    } catch (error) {
      setResetMessage(`Error: ${error.message}`);
    }
  };
  const handleSignUp = async (e) => {
    e.preventDefault();

    // Inside handleSignUp
    try {
      // Create user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Store the new user in Firestore with `isApproved: false`
      await setDoc(doc(db, "users", userCredential.user.uid), {
        email,
        displayName: userCredential.user.displayName || email,
        isApproved: false,
      });

      // Send verification email (optional but recommended)
      await sendEmailVerification(userCredential.user);

      // Sign out the user immediately
      await signOut(auth);

      // Set success message
      setSuccessMessage(
        "Account created successfully! Please check your email to verify your account, then log in."
      );

      // Optionally, redirect to login page after a delay
      setTimeout(() => {
        navigate("/auth");
      }, 5000); // Redirect after 5 seconds
    } catch (error) {
      console.error("Error signing up:", error.message);
      setError(error.message);
    }
  };

  return (
    <>
      <Navbar />
      <div className="auth-container">
        <form onSubmit={handleSignUp} className="auth-form">
          <h2>Sign Up</h2>
          {error && <p className="error-message">{error}</p>}
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {!successMessage && (
            <>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Password:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Sign Up</button>
              <div className="password-reset">
                <h3>Forgot Password?</h3>
                <form onSubmit={handlePasswordReset}>
                  <input
                    type="email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                  <button type="submit">Reset Password</button>
                </form>
                {resetMessage && <p>{resetMessage}</p>}
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default SignUp;
