// TestimonialsSection.js
import React from "react";

const TestimonialsSection = () => (
  <section className="testimonials">
    <h2>❤️ People Love Usely</h2>

    <div className="testimonials-content">
      <div className="testimonial">
        {/* <img
          src="https://www.adobe.com/federal/assets/svgs/adobe-logo.svg"
          alt="Adobe"
        /> */}
        <p>
          "Usely transformed our approach to usability. The scientific insights
          have been invaluable in refining our users experience. The data
          visualizations made it easy for our team to understand friction points
          and act quickly to resolve them."
        </p>
        <p>Director of UXR </p>
      </div>
      <div className="testimonial">
        {/* <img
          src="https://www.adobe.com/federal/assets/svgs/adobe-logo.svg"
          alt="Adobe"
        /> */}
        <p>
          "Partnering with Usely has elevated our software's usability to new
          heights. Their blend of science and creativity, principles and
          presented through stunning visualizations, is unmatched."
        </p>
        <p>VP Of Design</p>
      </div>
    </div>
  </section>
);

export default TestimonialsSection;
