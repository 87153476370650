// Lightbox.js
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./Lightbox.css";
import uselyLogo from "./images/usely-logo.svg";

const Lightbox = ({
  isVisible,
  onClose,
  message,
  secondaryMessage,
  isLoading,
  showCloseButton,
  showCloneSelect,
  cloneSelect,
  onConfirm,
  onCancel,
  onUpdate,
  showUpdateButton,
  cloneInProgress,
}) => {
  const [displayedMessage, setDisplayedMessage] = useState(message);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (!secondaryMessage) {
      setDisplayedMessage(message);
      setIsTransitioning(false);
    }

    if (secondaryMessage) {
      setIsTransitioning(true);
      const timer = setTimeout(() => {
        setDisplayedMessage(secondaryMessage);
        setIsTransitioning(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [message, secondaryMessage]);

  if (!isVisible) return null;

  return (
    <div className="lightbox">
      <div className="lightbox-logo-contain">
        <img src={uselyLogo} alt="Usely Logo" className="logo" />
      </div>
      <div className="lightbox-content">
        {isLoading && (
          <FontAwesomeIcon icon={faSpinner} spin className="loading-spinner" />
        )}
        <p className={`message ${isTransitioning ? "fade-out" : "fade-in"}`}>
          {displayedMessage}
        </p>
        {showCloneSelect && (
          <>
            {cloneSelect}
            <button
              type="button"
              onClick={onConfirm}
              className="confirm-clone-btn"
              disabled={isLoading || cloneInProgress}
            >
              Clone Data
            </button>
          </>
        )}
        {showUpdateButton && !isLoading && (
          <button
            type="button"
            onClick={onUpdate}
            className="update-button"
            disabled={isLoading || cloneInProgress}
          >
            Update Data
          </button>
        )}

        {/* Only show cancel when not cloning or updating */}
        {!isLoading &&
          message !== "Cloning release data..." &&
          message !== "Data updated successfully!" && (
            <button
              type="button"
              onClick={onCancel}
              className="cancel-button"
              disabled={cloneInProgress && !showUpdateButton}
            >
              Cancel
            </button>
          )}
        {!isLoading && showCloseButton && (
          <button
            type="button"
            onClick={onClose}
            className="close-lightbox-btn"
          >
            Done
          </button>
        )}
      </div>
    </div>
  );
};

export default Lightbox;
