// Auth.js

import React, { useState } from "react";
import { auth } from "./firebase";
import Navbar from "./Navbar";
import {
  signInWithPopup,
  signInWithRedirect,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  googleProvider,
  facebookProvider,
  twitterProvider,
  microsoftProvider,
} from "./authProviders";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faFacebook,
  faTwitter,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Auth = () => {
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleProviderLogin = async (provider) => {
    setLoading(true);
    try {
      await signInWithPopup(auth, provider);
      navigate("/home");
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAuthError = (error) => {
    switch (error.code) {
      case "auth/account-exists-with-different-credential":
        setError(
          `An account already exists with this email. Please sign in using ${error.customData.email}.`
        );
        break;
      case "auth/popup-closed-by-user":
        setError("Sign-in was cancelled. Please try again.");
        break;
      case "auth/cancelled-popup-request":
        setError("Only one sign-in window can be open at a time.");
        break;
      default:
        setError(`An error occurred: ${error.message}`);
    }
  };

  const handleEmailLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <Navbar />
      <div className="auth-container" role="main" aria-labelledby="auth-title">
        <h2 id="auth-title">Sign In or Sign Up</h2>
        <div
          className="auth-buttons"
          role="group"
          aria-label="Authentication options"
        >
          <button
            onClick={() => handleProviderLogin(googleProvider)}
            className="google-button"
            disabled={loading}
            aria-busy={loading}
          >
            <FontAwesomeIcon icon={faGoogle} aria-hidden="true" />
            <span>{loading ? "Loading..." : "Continue with Google"}</span>
          </button>
          <button
            onClick={() => handleProviderLogin(facebookProvider)}
            className="facebook-button"
          >
            <FontAwesomeIcon icon={faFacebook} /> Continue with Facebook
          </button>
          <button
            onClick={() => handleProviderLogin(twitterProvider)}
            className="twitter-button"
          >
            <FontAwesomeIcon icon={faTwitter} /> Continue with Twitter
          </button>
          <button
            onClick={() => handleProviderLogin(microsoftProvider)}
            className="microsoft-button"
          >
            <FontAwesomeIcon icon={faMicrosoft} /> Continue with Microsoft
          </button>
          <button onClick={handleEmailLogin} className="email-button">
            <FontAwesomeIcon icon={faEnvelope} /> Log in with Email
          </button>
        </div>

        {error && <div className="error-message">{error}</div>}
      </div>
    </>
  );
};

export default Auth;
