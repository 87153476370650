// Method.js
import React from "react";
import uselyScreens from "./images/usely-screens.png";
const Method = () => (
  <section className="method">
    <h2>Unlock Your User's Full Potential</h2>
    <img src={uselyScreens} alt="Usely" />
    <div className="method-stats">
      <div className="statcontain">
        <div className="stat">
          <h3>UX Fundalmentals</h3>
          <p>
            Leverage the proven framework of Nielsen's 10 usability heuristics
            to systematically evaluate and enhance your software's user
            experience.
          </p>
        </div>
        <div className="stat">
          <h3>Beautiful Data Visualizations</h3>
          <p>
            Experience your usability data like never before. Usely transforms
            complex metrics and insights into stunning, easy-to-understand
            visualizations.
          </p>
        </div>
        <div className="stat">
          <h3>Data-Driven Insights</h3>
          <p>
            Harness the power of rigorous scientific methods to accurately score
            and monitor the usability of your software. Our visual tools empower
            your teams to quickly grasp key findings and track progress over
            time.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Method;
